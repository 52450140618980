import { render, staticRenderFns } from "./enterpriseDetail.vue?vue&type=template&id=2da11ba2&scoped=true&"
import script from "./enterpriseDetail.vue?vue&type=script&lang=js&"
export * from "./enterpriseDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da11ba2",
  null
  
)

export default component.exports